@import url(https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,300;0,400;0,500;0,600;0,700;1,500&display=swap);

@charset "UTF-8";
:root {
  --blue: #4175d4;
  --blue-dark: #3663b8;
  --violet: #302877;
  --violet-dark: #272064;
  --violet-light: #a197ff;
  --grey: #828990;
  --statsH: 178px;
  --red: #b92022;
  --green: #66b139;
}

html {
  position: relative;
  min-height: 100%;
}

body {
  height: 100%;
}

.App {
  text-align: center;
  height: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.data-table-extensions.fieldcss {
  position: absolute;
} 

#loadingContent > div{
  width: 100% !important;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}

/* --------------------------- */
@font-face {
  font-family: 'icomoon';
  src: url(/static/media/icomoon.7da80e3a.eot);
  src: url(/static/media/icomoon.7da80e3a.eot#iefix) format("embedded-opentype"), url(/static/media/icomoon.8670f2cf.ttf) format("truetype"), url(/static/media/icomoon.d8f5d5ca.woff) format("woff"), url(/static/media/icomoon.ad0799d3.svg#icomoon) format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-feature-settings: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-image-favorite-active:before {
  content: "\e91a";
}

.icon-circle:before {
  content: "\e919";
}

.icon-warning:before {
  content: "\e918";
}

.icon-typeImg:before {
  content: "\e916";
}

.icon-typeVideo:before {
  content: "\e917";
}

.icon-logout:before {
  content: "\e914";
}

.icon-user:before {
  content: "\e915";
}

.icon-check:before {
  content: "\e913";
}

.icon-img-tags:before {
  content: "\e90f";
}

.icon-img-views:before {
  content: "\e910";
}

.icon-img-location:before {
  content: "\e911";
}

.icon-img-size:before {
  content: "\e912";
}

.icon-gallery:before {
  content: "\e905";
}

.icon-image-lightbox:before {
  content: "\e903";
}

.icon-image-favorite:before {
  content: "\e904";
}

.icon-paperclip:before {
  content: "\e902";
}

.icon-plus:before {
  content: "\e901";
}

.icon-arrow-right:before {
  content: "\e900";
}

.icon-social-youtube:before {
  content: "\e906";
}

.icon-arrow-down:before {
  content: "\e907";
}

.icon-close-icon:before {
  content: "\e908";
}

.icon-search-icon:before {
  content: "\e909";
}

.icon-search-filter:before {
  content: "\e90a";
}

.icon-social-facebook:before {
  content: "\e90b";
}

.icon-login-secure:before {
  content: "\e90c";
}

.icon-social-twitter:before {
  content: "\e90d";
}

.icon-social-instagram:before {
  content: "\e90e";
}

body {
  font-family: "Montserrat", sans-serif !important;
  overflow-x: hidden;
}

.textBlue {
  color: #4175d4;
  color: var(--blue);
}

.btn-box {
  cursor: pointer;
}

.backBlue {
  background-color: #4175d4;
  background-color: var(--blue);
}

.textViolet {
  color: #302877;
  color: var(--violet);
}

.hidefield {
  display: none;
}

.flexedCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

#formoutput div {
  display: flex;
  justify-content: start;
  align-items: center;
  width: 100%;
  position: relative;
  border: 2px solid #b92022;
  border: 2px solid var(--red);
  padding: 5px 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

#formoutput div span {
  color: #b92022;
  color: var(--red);
  font-weight: 600;
  margin-right: 10px;
}

#formoutput div p {
  color: #b92022;
  color: var(--red);
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  width: 100%;
  text-align: center;
}

.backViolet {
  background-color: #302877;
  background-color: var(--violet);
}

.icon-image-favorite-active {
  color: #302877;
  color: var(--violet);
}

.progressbar {
  text-align: center;
  color: white;
  font-weight: 600;
}

.pt-header {
  padding-top: 160px;
}

.content {
  padding-bottom: 40px;
}

.customScrollbars {
  max-height: 100vh !important;
  overflow-x: hidden !important;
}

.customScrollbars > div:first-child {
  max-height: 100vh !important;
  overflow-x: hidden !important;
  margin-bottom: 0 !important;
}

.customScrollbars > div:last-child {
  background-color: rgba(0, 0, 0, 0.6);
  right: 1px !important;
  z-index: 9;
}

.customScrollbars > div:last-child > div {
  background-color: rgba(255, 255, 255, 0.4) !important;
}

button:active, button:focus {
  outline: 0 !important;
}

.notificationCounter {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 0;
  border: 1px solid #b92022;
  border: 1px solid var(--red);
  width: auto;
  min-width: 16px;
  height: 16px;
  font-size: 9px;
  color: #b92022;
  color: var(--red);
  font-weight: 700;
  background-color: white;
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
}

.react-images__dialog > div {
  background: none !important;
}

.siteHeader {
  background-color: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 40px 30px 0;
  z-index: 5;
}

.siteHeader.home {
  background-color: transparent;
  position: absolute;
  top: 0;
  width: 100%;
  padding: 40px 30px 0;
  z-index: 5;
}

.siteHeader.defaultHeader:not(.home) {
  background-color: white;
  position: relative;
  padding: 32px 0;
  border-bottom: 1px solid #dedede;
  margin-bottom: 40px;
}

.siteHeader .row {
  flex-wrap: unset;
}

.lang-select {
  display: block;
  height: 100%;
}

.ul-lang-select {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
  list-style: none;
  padding: 0;
  margin: 0;
  text-align: right;
}

.langItem {
  cursor: pointer;
}

.langItem img {
  width: 30px;
}

.langItem.active {
  display: none;
}

.homeBG {
  background-image: url(/static/media/login-bg.44707fe7.jpg);
  background-position: center;
  background-size: cover;
  height: 100vh;
  display: flex;
}

.loginContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 30px;
}

.home-loginForm {
  width: 395px;
  border-radius: 15px;
  -webkit-border-radius: 15px;
  -moz-border-radius: 15px;
  -ms-border-radius: 15px;
  -o-border-radius: 15px;
  background-color: white;
  padding: 50px 40px 0px;
}

.loginForm .icon-login-secure {
  font-weight: bold;
}

.loginForm .form-group {
  margin-bottom: 30px;
}

.loginForm .form-group .error {
  padding-top: 5px;
}

.loginForm .form-group .error + input {
  border-color: #b92022;
  border-color: var(--red);
}

.loginForm .loginTitle {
  font-size: 24px;
  font-weight: 600;
  color: #302877;
  color: var(--violet);
  margin-bottom: 60px;
}

.loginForm input[type="text"], .loginForm input[type="password"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 10px;
  color: #202020;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  padding: 23px 30px;
  border: 2px solid #302877;
  border: 2px solid var(--violet);
  font-size: 14px;
  font-weight: 500;
  /*margin-bottom: 25px;*/
}

.loginForm input[type="text"]:focus, .loginForm input[type="password"]:focus {
  box-shadow: none;
  outline: 0;
}

.loginForm button {
  background-color: #302877;
  background-color: var(--violet);
  border-radius: 10px;
  color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  font-size: 14px;
  font-weight: 600;
  padding: 15px 0;
  text-align: center;
  width: 175px;
  border: 0;
  outline: 0;
  margin-bottom: 25px;
}

.loginForm button:hover, .loginForm button:active {
  background-color: #272064;
  background-color: var(--violet-dark);
  outline: 0;
}

.loginForm button:focus {
  outline: 0;
}

.loginForm .formLinks {
  font-size: 12px;
  color: #302877;
  color: var(--violet);
  font-weight: 700;
  margin-bottom: 25px;
}

.loginForm .formLinks a {
  font-size: 12px;
  color: #302877;
  color: var(--violet);
  font-weight: 700;
  margin-bottom: 25px;
}

.ata_logo {
  position: relative;
  z-index: 99999;
  width: 148px;
}

.defaultHeader .ata_logo > svg,
.defaultHeader .ata_logo .cls-1 {
  fill: #302877;
  fill: var(--violet);
}

.defaultHeader .ata_logo.white > svg,
.defaultHeader .ata_logo.white .cls-1 {
  fill: white;
}

.defaultHeader_components .searchForm,
.searchForm:not(.home) {
  width: 60%;
}

.defaultHeader_components .searchForm.home {
  width: calc(100% - 110px);
  margin: auto;
}

.searchForm.home .searchForm_wrapper {
  padding: 0;
  justify-content: center;
}

.searchForm.home .searchForm_field input[type="text"] {
  background-color: #fff;
}

.searchForm .searchForm_wrapper {
  display: flex;
  align-items: center;
  padding-left: 20px;
}

.searchForm .searchForm_field {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  max-width: 640px;
  width: 100%;
}

.searchForm .searchForm_field input[type="text"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #dcdcdc;
  font-size: 15px;
  font-weight: 600;
  color: #9c9c9c;
  padding: 30px 15px;
  padding-right: 60px;
  border: 0;
  width: 100%;
  line-height: 1;
  border-radius: 0;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.searchForm .searchForm_field input[type="text"]::-webkit-input-placeholder, .searchForm .searchForm_field input[type="text"]::-webkit-input-placeholder {
  font-weight: 400;
  font-style: italic;
}

.searchForm .searchForm_field input[type="text"]::-webkit-input-placeholder {
  font-weight: 400;
  font-style: italic;
}

.searchForm .searchForm_field input[type="text"]::-webkit-input-placeholder, .searchForm .searchForm_field input[type="text"]::placeholder {
  font-weight: 400;
  font-style: italic;
}

.searchForm .searchForm_field input[type="text"]:active, .searchForm .searchForm_field input[type="text"]:focus {
  border: 0;
  box-shadow: none;
}

.searchForm .searchForm_field .searchForm_filter {
  position: absolute;
  right: 0;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  padding: 0;
  width: 60px;
  height: 40px;
  border-left: 1px solid #9c9c9c;
  background-color: transparent;
}

.searchForm .searchForm_field .searchForm_filter span {
  font-size: 26px;
  color: #9c9c9c;
  font-weight: bold;
}

.searchForm .searchForm_submit .searchSubmit,
.siteHeader .searchSubmit {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  height: 60px;
  width: 60px;
  padding: 0;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  border: 0;
  background-color: #302877;
  background-color: var(--violet);
}

.searchForm .searchForm_submit .searchSubmit span,
.siteHeader .searchSubmit span {
  font-size: 26px;
  color: white;
}

.buttonSideSearch .searchSubmit {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.buttonSideSearch .searchSubmit span {
  font-size: 20px;
}

.defaultHeader .defaultHeader_components {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  flex-wrap: wrap;
}

.defaultHeader_components > div {
  display: inline-block;
  position: relative;
  width: auto;
  flex: 1 0 auto;
  min-width: 0;
}

.customSelect {
  position: relative;
  text-align: right;
}

.customSelect > div {
  position: absolute;
  width: 100%;
}

.customSelect-label {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 16px;
  color: #000;
  font-weight: 600;
  border: 0;
  background-color: transparent;
}

.FeaturedimgsSelect_button {
  font-size: 14px;
  font-weight: 700;
  border: 1px solid black;
  height: 50px;
  width: 100%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  min-width: 175px;
  width: auto;
  padding: 0 30px;
}

.defaultHeader_components .galleriesSelect_button span {
  color: #302877;
  color: var(--violet);
  margin-left: 10px;
  font-size: 14px;
  display: inline-block;
  position: relative;
}

.galleriesSelect_button span {
  transition: 0.6s ease;
  transform: rotate(-90deg);
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
}

.galleriesSelect .galleriesSelect_button.active span {
  transition: 0.6s ease;
  transform: rotate(0deg);
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -ms-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  -webkit-transition: 0.6s ease;
  -moz-transition: 0.6s ease;
  -ms-transition: 0.6s ease;
  -o-transition: 0.6s ease;
}

.FeaturedimgsSelect_button span {
  position: relative;
  left: 15px;
}

.customSelect .customSelect-menu {
  text-align: left;
  transition: all 600ms ease-in;
  position: absolute;
  width: calc(100% + 40px);
  background-color: #302877;
  background-color: var(--violet);
  list-style: none;
  padding: 2px 2px 10px;
  margin: 0;
  margin-top: 15px;
  z-index: 9;
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.4);
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  -webkit-transition: all 600ms ease-in;
  -moz-transition: all 600ms ease-in;
  -ms-transition: all 600ms ease-in;
  -o-transition: all 600ms ease-in;
}

.FeaturedimgsSelect.customSelect .customSelect-menu {
  width: 100%;
  max-width: 175px;
  right: 0;
  z-index: 9999;
}

.FeaturedimgsSelect.customSelect .customSelect-menu li {
  display: block;
  font-size: 14px;
  color: #828990;
  color: var(--grey);
  font-weight: 500;
  line-height: 1.1;
  text-decoration: none;
  display: block;
  margin: 7px;
  padding: 7px 15px;
}

.customSelect .customSelect-menu span {
  font-weight: 500;
  color: white;
  margin-right: 7px;
}

.customSelect .customSelect-menu li a,
.customSelect .customSelect-menu li div {
  font-size: 14px;
  color: white;
  font-weight: 500;
  line-height: 1.1;
  text-decoration: none;
  display: block;
  margin: 7px;
  padding: 7px 15px;
}

.customSelect .customSelect-menu li a:hover,
.customSelect .customSelect-menu li div:hover {
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  color: white;
}

.FeaturedimgsSelect.customSelect .customSelect-menu li:hover {
  background-color: rgba(255, 255, 255, 0.3);
  cursor: pointer;
  color: white;
}

.defaultHeader_components .lang-select {
  padding: 0 15px;
  flex: none;
  width: 60px;
}

.defaultHeader_components .userLogged {
  position: relative;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  text-align: center;
  background-color: transparent;
  border: 1px solid #302877;
  border: 1px solid var(--violet);
  padding: 10px;
  line-height: 1;
  color: #302877;
  color: var(--violet);
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.defaultHeader_components .userLogged .notificationCounter {
  position: absolute;
  top: -5px;
  right: -5px;
}

.siteHeader.home .defaultHeader_components .userLogged {
  border: 1px solid white;
  color: white;
}

.siteHeader.home .defaultHeader_components .galleriesSelect_button {
  color: white;
}

.siteHeader.home .defaultHeader_components .galleriesSelect_button span {
  color: white;
}

.defaultHeader_components .searchForm-Inner {
  position: relative;
  /*width: 60%;*/
  /*width: 100%;*/
  /* max-width: 470px;*/
}

.defaultHeader_components .searchForm-Inner .searchForm {
  width: 100%;
}

.defaultHeader_components .galleriesSelect {
  flex: none;
  width: 135px;
}

.defaultHeader_components .userArea {
  flex: none;
  min-width: 160px;
}

.defaultHeader_components .customSelect {
  padding-left: 15px;
}

.modalRegist,
.modalFiltersearch {
  width: 100vw;
  min-height: 100vh;
  background: #302877;
  background: var(--violet);
  z-index: 9999;
}

.modalLicense {
  background: #302877;
  background: var(--violet);
}

.modalRegist > .modal-dialog,
.modalFiltersearch > .modal-dialog {
  max-width: none;
  margin: auto;
}

.modalRegist > .modal-dialog > .modal-content,
.modalFiltersearch > .modal-dialog > .modal-content {
  border: 0;
  background-color: transparent;
  padding: 0 30px 30px;
}

.modalRegist > .modal-dialog > .modal-content .inmodalLightbox,
.modalFiltersearch > .modal-dialog > .modal-content .inmodalLightbox {
  position: fixed;
  max-width: none;
  top: 0;
  left: 0;
}

.licenseContent {
  white-space: pre-wrap;
  padding-top: 60px;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.preload-stuff {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: 9;
  background-color: rgba(48, 40, 119, 0.6);
  /*backdrop-filter: blur(5px);*/
}

.preload-stuff .message-box .loading-message {
  display: block;
  text-align: center;
  color: white;
  margin-top: 10px;
  font-weight: 600;
  font-size: 16px;
}

.preload-stuff .message-box img {
  display: block;
  margin: auto;
}

.preload-stuff ~ * {
  filter: blur(5px);
  -webkit-filter: blur(5px);
}

.message-boxPopup {
  padding: 30px;
  background-color: white;
  width: 360px;
  position: relative;
  display: block;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  box-shadow: 4px 4px 7px rgba(0, 0, 0, 0.4);
}

.message-boxPopup .messagedel {
  display: block;
  font-size: 16px;
  color: #302877;
  color: var(--violet);
  font-weight: 600;
  line-height: 1.3;
  position: relative;
  padding-bottom: 15px;
}

.message-boxPopup .message-boxActions {
  display: flex;
  justify-content: flex-end;
  position: relative;
  margin-top: 15px;
}

.message-boxPopup .message-boxActions .buttonyesorno {
  border-radius: 5px;
  display: block;
  background-color: #302877;
  background-color: var(--violet);
  line-height: 1;
  padding: 10px 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  color: white;
  font-weight: 500;
  cursor: pointer;
  margin-left: 10px;
}

.message-boxPopup .message-boxActions .buttonyesorno:hover {
  background-color: #272064;
  background-color: var(--violet-dark);
}

.contentFiltersearch {
  position: relative;
  margin-top: calc(50% - 480px);
}

.contentFiltersearch .titleFiltersearch {
  font-size: 32px;
  font-weight: 700;
  color: white;
  text-align: center;
  margin-bottom: 20px;
}

.contentFiltersearch_wrapper {
  width: 588px;
  margin: auto;
}

.contentFiltersearch_wrapper .searchForm.modalFilter {
  display: flex;
  width: 100%;
  margin-bottom: 30px;
}

.contentFiltersearch_wrapper .searchForm.modalFilter .searchForm_field input[type="text"] {
  background-color: white;
}

.contentFiltersearch_wrapper .searchForm.modalFilter .searchForm_submit .searchSubmit {
  background-color: #4175d4;
  background-color: var(--blue);
}

.contentFiltersearch_wrapper .filterFields {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

.contentFiltersearch_wrapper .filterFields select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  font-size: 14px;
  color: black;
  font-weight: bold;
  cursor: pointer;
  padding: 0 15px;
  background-color: white;
  height: 50px;
  display: block;
  width: 100%;
  max-width: 175px;
  border-radius: 10px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3MC41OSAzNS4yOSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMwMDA7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJMYXllcl8yIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxnIGlkPSJMYXlvdXQiPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIzNS4yOSAwIDcwLjU5IDAgMzUuMjkgMzUuMjkgMCAwIDM1LjI5IDAiLz48L2c+PC9nPjwvc3ZnPg==");
  background-repeat: no-repeat;
  background-position: calc(100% - 15px) 50%;
  background-size: 14px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.contentFiltersearch_wrapper .filterFields select:disabled {
  cursor: no-drop;
  opacity: 0.75;
}

.contentFiltersearch_wrapper .filterSubmit .filterSubmit_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  position: relative;
}

.contentFiltersearch_wrapper .filterSubmit .filterSubmit_wrapper button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  height: 50px;
  display: block;
  width: 100%;
  min-width: 175px;
  border: 10px;
  font-size: 14px;
  font-weight: 700;
  color: white;
  border-radius: 10px;
  background-color: #4175d4;
  background-color: var(--blue);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.contentLatestsearch .contentLatestsearch_wrapper {
  padding-top: 40px;
  width: 588px;
  margin: auto;
}

.contentLatestsearch .contentLatestsearch_wrapper .latestsSearch_title {
  font-size: 24px;
  font-weight: 500;
  color: white;
  margin-bottom: 20px;
}

.contentLatestsearch .contentLatestsearch_wrapper .latestsSearch_List {
  display: flex;
  margin-left: -7px;
  margin-right: -7px;
}

.contentLatestsearch .contentLatestsearch_wrapper .latestsSearch_List .latestsSearch_item {
  position: relative;
  overflow: hidden;
  padding: 7px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.itemWrapper:not(.imagePreview) {
  position: relative;
  background-color: white;
  border-radius: 5px;
  height: 136px;
  overflow: hidden;
  background-size: cover;
  background-position: center;
}

.itemWrapper:not(.imagePreview) img {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  object-fit: cover;
}

.itemWrapper:not(.imagePreview) .itemWrapper_hover {
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  align-items: flex-end;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  z-index: 9;
}

.itemWrapper:not(.imagePreview) .itemWrapper_hover .iconsHover {
  position: absolute;
  bottom: 15px;
  right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemWrapper:not(.imagePreview) .itemWrapper_hover .itemTitle {
  position: absolute;
  text-align: left;
  font-size: 12px;
  color: white;
  left: 10px;
  margin: 0;
  font-weight: 500;
  width: calc(100% - 80px);
}

.itemWrapper:not(.imagePreview) .itemWrapper_hover .itemLink {
  display: block;
  width: 100%;
  height: 100%;
  position: relative;
}

.itemWrapper:not(.imagePreview) .itemWrapper_hover .itemLink .itemType, .itemWrapper:not(.imagePreview) .itemWrapper_hover .itemType {
  display: block;
  color: white;
  font-size: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0.6;
}

.itemWrapper:not(.imagePreview) .itemWrapper_hover .itemWrapper_actions, .itemWrapper:not(.imagePreview) .itemWrapper_hover .iconsHover {
  position: absolute;
  bottom: 0px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.itemWrapper:not(.imagePreview):hover .itemWrapper_hover {
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.ck.ck-editor__main > .ck-editor__editable {
  height: 300px;
}


.itemWrapper:not(.imagePreview) .itemWrapper_hover .btfavtlight{
    border: 0;
    margin-left: 0px !important;
    margin-right: 10px;
    margin-bottom: 10px;
    bottom: 0px !important; 
    right: 0px !important;
    position: relative !important;
}

.itemWrapper:not(.imagePreview) .itemWrapper_hover button,
.itemWrapper:not(.imagePreview) .itemWrapper_hover .iconsHover button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border: 0;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  position: absolute;
  bottom: 10px;
  right: 10px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.inmodalLightbox {
  z-index: 99;
}

.inmodalLightbox > div {
  height: 100vh !important;
  background-color: rgba(0, 0, 0, 0.8) !important;
}

.imgFavorite {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  width: 25px;
  height: 25px;
  background-color: #fff;
  border: 0;
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.itemWrapper_hover button span:not(.icon-image-favorite-active),
.imgFavorite span:not(.icon-image-favorite-active),
.itemWrapper_hover .iconsHover button span:not(.icon-image-favorite-active) {
  color: #898989;
  font-size: 12px;
  font-weight: bolder;
}

.contentLatestsearch
.contentLatestsearch_wrapper
.latestsSearch_List
.latestsSearch_item
.itemWrapper
.itemWrapper_hover
button
span.icon-image-favorite-active,
.imgFavorite span.icon-image-favorite-active,
.itemWrapper_hover .iconsHover button span.icon-image-favorite-active {
  color: #302877;
  color: var(--violet);
  font-size: 12px;
  font-weight: bolder;
}

.linkBtn {
  cursor: pointer;
}

.registHeader,
.headerFiltersearch {
  position: relative;
  padding: 30px 0;
  z-index: 99;
}

.forgotpassword {
  cursor: pointer;
}

.registHeader .registHeader-close,
.headerFiltersearch .Filtersearch-close {
  position: absolute;
  top: 40px;
  right: 0;
  padding: 10px 20px;
  color: white;
  line-height: 1;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  border: 1px solid white;
}

.registHeader .registHeader-close span,
.headerFiltersearch .Filtersearch-close span {
  font-size: 11px;
  margin-right: 5px;
}

.registAta {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin-top: -130px;
}

.registForm {
  display: block;
  margin: 0 80px;
}

.registForm .disableFField {
  opacity: 0.6;
}

.registForm .disableFField input, .registForm .disableFField select {
  pointer-events: none;
  cursor: no-drop;
}

.registForm input[type="text"], .registForm input[type="password"], .registForm input[type="email"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 23px 30px;
  margin: 0;
  line-height: 1;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid white;
  color: white;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  font-size: 14px;
  font-weight: 700;
}

.registForm input[type="text"]::-webkit-input-placeholder, .registForm input[type="text"]::-webkit-input-placeholder, .registForm input[type="password"]::-webkit-input-placeholder, .registForm input[type="password"]::-webkit-input-placeholder, .registForm input[type="email"]::-webkit-input-placeholder, .registForm input[type="email"]::-webkit-input-placeholder {
  color: white;
  font-style: italic;
  font-weight: 500;
}

.registForm input[type="text"]::-webkit-input-placeholder, .registForm input[type="password"]::-webkit-input-placeholder, .registForm input[type="email"]::-webkit-input-placeholder {
  color: white;
  font-style: italic;
  font-weight: 500;
}

.registForm input[type="text"]::-webkit-input-placeholder, .registForm input[type="text"]::placeholder, .registForm input[type="password"]::-webkit-input-placeholder, .registForm input[type="password"]::placeholder, .registForm input[type="email"]::-webkit-input-placeholder, .registForm input[type="email"]::placeholder {
  color: white;
  font-style: italic;
  font-weight: 500;
}

.registForm .importantSpecs {
  position: absolute;
  bottom: -22px;
  left: 0;
  line-height: 1;
  padding: 0 5px 2px;
  border: 2px solid white;
  border-top-color: #302877;
  border-top-color: var(--violet);
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.registForm .importantSpecs span {
  color: black;
  font-size: 14px;
  font-weight: 600;
  color: white;
  cursor: help;
}

.registForm .importantSpecs span:hover ~ .importantSpecs_content {
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.registForm .importantSpecs .importantSpecs_content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  background-color: white;
  z-index: 9;
  width: 240px;
  font-size: 14px;
  color: black;
  line-height: 1.4;
  top: 0;
  margin-top: -40px;
  margin-left: 40px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.4);
  padding: 10px;
}

.registForm select:active, .registForm select:focus {
  outline: none;
  box-shadow: none;
}

.registForm input[type="text"]:active, .registForm input[type="text"]:focus {
  outline: none;
  box-shadow: none;
}

.registForm input[type="password"]:active, .registForm input[type="password"]:focus {
  outline: none;
  box-shadow: none;
}

.registForm input[type="email"]:active, .registForm input[type="email"]:focus {
  outline: none;
  box-shadow: none;
}

.registForm select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 0 30px;
  margin: 0;
  cursor: pointer;
  line-height: 1;
  background-color: transparent;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid white;
  color: white;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  font-size: 14px;
  font-weight: 700;
  height: 48px;
  width: 100%;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3MiA3MiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJMYXlvdXQiPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIzNiAyMi40NyA2OC40NyAyMi40NyAzNiA1NC45NCAzLjUzIDIyLjQ3IDM2IDIyLjQ3Ii8+PC9nPjwvc3ZnPg==");
  background-size: 16px;
  background-repeat: no-repeat;
  background-position: calc(100% - 30px) 50%;
}

.registForm select option {
  color: #000;
}

.registForm .fileField {
  display: flex;
  align-items: center;
  position: relative;
  border-bottom: 2px solid white;
  padding: 10px 30px;
}

.registForm .fileField input[type="file"] {
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}

.registForm .fileField .iconFile_Placeholder {
  position: absolute;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: white;
  z-index: 2;
}

.registForm .fileField .iconFile_button {
  position: absolute;
  right: 0px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 30px;
  width: 100%;
}

.registForm .fileField .iconFile_button .icon-plus {
  color: #4175d4;
  color: var(--blue);
  font-size: 14px;
  -webkit-text-stroke-width: 1px;
  position: absolute;
  right: 0;
}

.registForm .fileField .iconFile_button .iconFile_Name {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  color: white;
  margin-right: 40px;
  display: flex;
}

.registForm .fileField .iconFile_button .iconFile_Name a {
  display: block;
  color: white;
}

.registForm .fileField .iconFile_button .iconFile_Name > span {
  margin-right: 5px;
  font-size: 16px;
  color: #4175d4;
  color: var(--blue);
}

.registForm .countrySelector div[class*="control"] {
  background-color: transparent;
  border: 0;
  border-bottom: 2px solid #f3f3f3;
  border-radius: 0;
  font-size: 14px;
  padding: 0 20px;
  box-shadow: none;
  cursor: pointer;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.registForm .countrySelector div[class*="control"] > div:not([class*="IndicatorsContainer"]) {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.registForm .countrySelector div[class*="control"] div[class*="IndicatorsContainer"] span[class*="indicatorSeparator"] {
  display: none;
}

.registForm .countrySelector div[class*="control"] div[class*="IndicatorsContainer"] div[class*="indicatorContainer"] {
  position: relative;
}

.registForm .countrySelector div[class*="control"] div[class*="IndicatorsContainer"] div[class*="indicatorContainer"] svg {
  display: none;
}

.registForm .countrySelector div[class*="control"] div[class*="IndicatorsContainer"] div[class*="indicatorContainer"]:after {
  content: "";
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3MiA3MiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNmZmY7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJMYXlvdXQiPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIzNiAyMi40NyA2OC40NyAyMi40NyAzNiA1NC45NCAzLjUzIDIyLjQ3IDM2IDIyLjQ3Ii8+PC9nPjwvc3ZnPg==");
  background-size: 16px;
  background-repeat: no-repeat;
  top: 0;
}

.registForm .countrySelector div[class*="menu"] {
  z-index: 9;
}

.registForm .countrySelector div[class*="ValueContainer"] {
  height: 46px;
}

.registForm .countrySelector div[class*="ValueContainer"] div[class*="placeholder"] {
  color: white;
  font-style: italic;
  font-weight: 500;
}

.registForm .countrySelector div[class*="ValueContainer"] div[class*="singleValue"] {
  color: white;
  font-weight: 700;
}

.registForm .countrySelector div[class*="Input"] {
  color: white;
}

.registTitle {
  font-size: 24px;
  font-weight: 600;
  color: white;
  margin-bottom: 40px;
}

.info-renew {
  position: relative;
  background-color: #4175d4;
  background-color: var(--blue);
  margin-bottom: 10px;
  color: white;
  font-weight: 500;
  padding: 15px;
  padding-left: 40px;
  line-height: 1.2;
  border-radius: 5px;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.info-renew span {
  position: absolute;
  top: 15px;
  left: 10px;
  font-size: 20px;
}

.info-renewstandby {
  position: relative;
  background-color: #f3f3f3;
  margin-bottom: 10px;
  color: black;
  font-weight: 500;
  padding: 15px;
  padding-left: 40px;
  line-height: 1.2;
  border-radius: 5px;
  text-align: center;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.info-renewstandby span {
  position: absolute;
  top: 15px;
  left: 10px;
  font-size: 20px;
  color: #302877;
  color: var(--violet);
}

.formSubmit {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  margin-top: 15px;
}

.formSubmit .termsCondition {
  display: flex;
  padding-right: 30px;
  width: 68%;
}

.formSubmit p {
  margin: 0;
  color: white;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
}

.formSubmit p a {
  text-decoration: underline;
  color: white;
}

.formSubmit button, .customSubmitBtn {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: #4175d4;
  background-color: var(--blue);
  padding: 15px 30px;
  width: auto;
  min-width: 175px;
  text-align: center;
  cursor: pointer;
  outline: 0;
  border: 0;
  border-radius: 10px;
  font-size: 14px;
  font-weight: 700;
  color: white;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.formSubmit button:not(:disabled):hover, .formSubmit button:not(:disabled):active, .customSubmitBtn:not(:disabled):hover, .customSubmitBtn:not(:disabled):active {
  background-color: #3663b8;
  background-color: var(--blue-dark);
  outline: 0;
}

.formSubmit button:not(:disabled):focus, .customSubmitBtn:not(:disabled):focus {
  outline: 0;
}

.formSubmit button[disabled], .customSubmitBtn[disabled] {
  opacity: 0.8;
  cursor: no-drop;
}

.formSubmit button.violet, .customSubmitBtn.violet {
  background-color: #302877;
  background-color: var(--violet);
}

.formSubmit button.violet:hover, .customSubmitBtn.violet:hover {
  background-color: #272064;
  background-color: var(--violet-dark);
}

.formSubmit button.black, .customSubmitBtn.black {
  background-color: black;
}

.formSubmit button.black:hover, .customSubmitBtn.black:hover {
  background-color: #131313;
}

.termsRadio {
  display: inline-table;
  width: 17px;
  min-width: 17px;
  height: 17px;
  border: 1px solid white;
  border-radius: 20px;
  margin-right: 20px;
  margin-top: 5px;
  cursor: pointer;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  padding: 2px;
}

.termsRadio.active::after {
  content: "";
  background: white;
  display: inherit;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.centercontent {
  text-align: center;
}

.breadcrumb {
  background: transparent;
  padding: 0;
  margin-bottom: 10px;
}

.breadcrumb-item {
  font-size: 12px;
  font-weight: 600;
  color: black;
  text-decoration: none;
  padding: 0;
}

.breadcrumb-item.active,
.breadcrumb-item a {
  font-size: 12px;
  font-weight: 600;
  color: black;
  text-decoration: none;
  padding: 0;
}

.breadcrumb-item:before {
  width: 25px;
  height: 1px;
  background-color: black;
  font-size: 0;
  margin: 8px 10px 0;
}

/*PAGE DEFAULT*/
.backBtn {
  color: #302877;
  color: var(--violet);
  font-size: 16px;
  font-weight: 600;
  display: inline-flex;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
}

.backBtn i {
  font-size: 20px;
  margin-right: 10px;
}

.contentPage {
  padding-bottom: 40px;
}

.imagePreview {
  position: relative;
}

.imagePreview img {
  width: 100%;
  height: auto;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.imagePreview .imgFavorite {
  position: absolute;
  margin-top: 20px;
  margin-left: 20px;
  z-index: 99;
}

.imagePreview .imgFavorite button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.imagePreview .itemWrapper_video {
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s ease;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  padding: 10px;
  justify-content: flex-end;
  align-items: flex-end;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  cursor: pointer;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  z-index: 9;
}

.imagePreview .itemWrapper_video .itemType {
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 70px;
  width: 100%;
  height: 100%;
}

.imagePreview:hover .itemWrapper_video {
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.legaltermsconta.row {
  margin-top: 120px;
}

.btsFormsConta {
  display: flex;
  flex-wrap: wrap;
  margin-top: 14px;
}

.btformconta {
  background-color: #fff;
  padding: 10px 18px 10px 18px;
  border-radius: 8px;
  margin-right: 21px;  
  cursor: pointer;
  color: #302877;
  font-weight: bold;
}

.titleForm {
  color: #fff !important;
}

.titlePage {
  font-size: 32px;
  font-weight: 600;
  color: black;
  margin-bottom: 40px;
  line-height: 1.2;
}

.mylistRowEmpty .mylistEmpty {
  font-size: 16px;
  color: #302877;
  color: var(--violet);
  font-weight: 500;
  text-align: center;
  padding: 15px 0;
}

.Sideinfo .titlePage {
  margin-bottom: 10px;
  line-height: 1.3;
  width: auto;
}

.Sideinfo .locationImg {
  margin-bottom: 40px;
  font-size: 12px;
  font-weight: 600;
  color: #302877;
  color: var(--violet);
  line-height: 1;
}

.statsItem.likebypage {
  cursor: pointer;
}

.Sideinfo .statsImg .statsItem {
  font-size: 12px;
  font-weight: 600;
  color: #302877;
  color: var(--violet);
  line-height: 1;
}

.Sideinfo .locationImg span,
.Sideinfo .statsImg .statsItem span,
.Sideinfo .tagsImg span {
  margin-right: 10px;
  font-size: 14px;
  color: #302877;
  color: var(--violet);
  font-weight: 600;
}

.Sideinfo .descriptionImg {
  font-size: 16px;
  font-weight: 400;
  color: black;
  line-height: 1.3;
  min-height: 178px;
  white-space: pre-wrap;
}

.Sideinfo .warninginMylist {
  color: #302877;
  color: var(--violet);
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  line-height: 1.3;
}

.Sideinfo .warninginMylist a {
  color: #302877;
  color: var(--violet);
  text-decoration: underline;
}

.Sideinfo .statsImg {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 30px 0;
}

.Sideinfo .autorImg {
  font-size: 12px;
  font-weight: 600;
  color: #302877;
  color: var(--violet);
  line-height: 1;
  margin-bottom: 30px;
}

.Sideinfo .autorImg span {
  margin-right: 10px;
  font-size: 14px;
  color: #302877;
  color: var(--violet);
  font-weight: 600;
}

.Sideinfo .tagsImg {
  display: flex;
  margin-bottom: 30px;
}

.Sideinfo .tagsImg .tagsImg-wrapper {
  display: flex;
  flex-wrap: wrap;
}

.Sideinfo .tagsImg .tagsImg-wrapper .tagsImg-item {
  background-color: #dcdcdc;
  display: block;
  border-radius: 3px;
  padding: 4px 10px;
  line-height: 1;
  font-size: 12px;
  margin-bottom: 5px;
  margin-right: 5px;
  font-weight: 600;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  cursor: pointer;
}

.Sideinfo .licenseImg {
  display: flex;
  margin-bottom: 30px;
}

.Sideinfo .licenseImg p {
  font-size: 12px;
  line-height: 1.2;
  font-weight: 600;
  color: black;
  margin: 0;
}

.Sideinfo .licenseImg p a {
  color: black;
  text-decoration: underline;
}

.licenseImg .termsRadio {
  margin-top: 0;
  border-color: black;
}

.licenseImg .termsRadio.active::after {
  background: black;
}

.Sideinfo .addImg {
  display: flex;
  justify-content: center;
}

.Sideinfo .addImg button {
  background-color: #302877;
  background-color: var(--violet);
  padding-left: 0;
  padding-right: 0;
}

.Sideinfo .addImg button:not(:disabled):hover, .Sideinfo .addImg button:not(:disabled):active {
  background-color: #272064;
  background-color: var(--violet-dark);
  outline: 0;
}

/* HOMEPAGE */
.bannerHomepage_inner {
  position: relative;
  min-height: calc(100vh - 178px);
  min-height: calc(100vh - var(--statsH));
  background-size: cover;
  background-position: top center;
}

.buttonlicenseContent .addimg_toList {
  background-color: #302877;
  background-color: #302877;
  background-color: var(--violet);
  color: #fff;
  padding: 10px 20px;
  line-height: 1;
  font-size: 16px;
  font-weight: 500;
  border-radius: 10px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #fff;
}

.bannerHomepage_wrapper {
  position: absolute;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  -o-transform: translateX(-50%);
}

.bannerHomepage_wrapper .homeTitle {
  display: block;
  color: white;
  font-size: 32px;
  font-weight: 700;
  line-height: 1.2;
  margin-bottom: 15px;
  text-align: center;
}

.bannerHomepage_wrapper .homesubTitle {
  display: block;
  color: white;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 40px;
  text-align: center;
}

.bannerHomepage_wrapper .photoFeatured {
  position: absolute;
  line-height: 1.2;
  bottom: 30px;
  left: 15px;
  color: white;
  font-size: 12px;
}

#loadingStats {
  display: block;
  width: 120px;
  height: 32px;
  margin: auto;
  position: relative;
}

#loadingStats > div {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 100%;
  transform: translate(-50%, -50%);
  width: calc(100% + 120px) !important;
  height: calc(100% + 32px) !important;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

#loadingContent {
  display: block;
  width: 100%;
  height: 120px;
  margin: 120px auto;
  position: relative;
}

#loadingContent > div {
  position: absolute;
  top: 50%;
  left: 50%;
  max-height: 100%;
  transform: translate(-50%, -50%);
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  width: calc(100% + 360px);
  height: calc(100% + 120px) !important;
}

.ata_stats {
  height: 178px;
  height: var(--statsH);
}

.ata_stats .ata_statsWrapper {
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-around;
}

.ata_stats .ata_statsWrapper .ata_statsItem {
  flex: none;
  width: auto;
  max-width: none;
  text-align: center;
  line-height: 1;
  padding: 20px 0;
}

.ata_stats .ata_statsWrapper .ata_statsItem span {
  font-size: 32px;
  font-weight: 700;
  color: #000;
  display: block;
}

.ata_stats .ata_statsWrapper .ata_statsItem label {
  font-size: 24px;
  font-weight: 600;
  color: #000;
}

.headFeaturedimgs {
  align-items: center;
  padding-top: 60px;
  margin-bottom: 10px;
}

.headFeaturedimgs.noImages span {
  display: block;
  color: #302877;
  color: var(--violet);
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
  margin-bottom: 40px;
  text-align: center;
}

.pageTitle {
  font-size: 32px;
  color: #000;
  font-weight: 700;
  margin: 0;
  line-height: 1.3;
}

.pagesubTitle {
  font-size: 24px;
  color: #000;
  font-weight: 600;
  margin: 0;
  margin-bottom: 10px;
}

.react-photo-gallery--gallery {
  padding: 15px 15px 40px;
  /*.itemWrapper{
    max-height: 280px !important;
    height: 100% !important;
    img{

    }
  }*/
}

.react-photo-gallery--gallery > div {
  margin-right: -15px;
  margin-left: -15px;
  margin-top: -15px;
}

.galleryMore {
  padding: 40px 0;
  margin-top: -15px;
  display: flex;
  justify-content: center;
}

.galleryMore_button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: black;
  color: white;
  font-weight: 700;
  font-size: 14px;
  padding: 15px 20px;
  border: 0;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.galleryMore_button span {
  margin-right: 30px;
}

/* SEARCH FILTER */
.headSearchresults {
  display: flex;
  align-items: center;
  padding-bottom: 40px;
}

.headSearchresults .pageTitle {
  font-weight: 600;
}

.headSearchresults .FeaturedimgsSelect .FeaturedimgsSelect_button {
  padding: 0;
}

.sideMenuContent {
  background-color: #f3f3f3;
  padding: 15px;
}

.sideMenuContent hr {
  margin: 0;
  width: 100%;
  border-bottom: 1px solid #828990;
  border-bottom: 1px solid var(--grey);
}

#rccundefined {
  width: 100%;
}

.sideMenuContent .ContentType:not(.filters) {
  margin: 0;
  padding-bottom: 15px;
  /*padding-bottom: 15px;
        flex-wrap: nowrap;*/
}

.sideMenuContent .CollapsibleItem .accordion-head {
  background-color: transparent;
  position: relative;
  font-size: 14px;
  font-weight: 700;
  padding: 15px 0;
  color: #828990;
  color: var(--grey);
}

.sideMenuContent .CollapsibleItem .accordion-head:after {
  content: "\e907";
  font-family: "icomoon";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.sideMenuContent .CollapsibleItem .accordion-head.active-accordion:after {
  top: 0;
  bottom: 50%;
  transform: rotate(-180deg) translateY(-35%);
  -webkit-transform: rotate(-180deg) translateY(-35%);
  -moz-transform: rotate(-180deg) translateY(-35%);
  -ms-transform: rotate(-180deg) translateY(-35%);
  -o-transform: rotate(-180deg) translateY(-35%);
}

.sideMenuContent .CollapsibleItem .accordion-head.noSubcat {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sideMenuContent .CollapsibleItem .accordion-head.noSubcat .customCheckbox input {
  margin-right: 0;
  margin-left: 10px;
}

.sideMenuContent .CollapsibleItem .accordion-head.noSubcat::after {
  display: none;
}

.sideMenuContent .CollapsibleItem .CollapsibleContent {
  background-color: transparent;
  padding: 0 0 15px;
}

.sideMenuContent .CollapsibleItem .CollapsibleContent .form-group {
  padding-bottom: 5px;
  padding-right: 5px;
}

.sideMenuContent .customCheckbox {
  padding: 0;
  display: flex;
  align-items: flex-start;
  /*justify-content: center;*/
}

.sideMenuContent .customCheckbox input[type="checkbox"] ~ label {
  font-size: 12px;
  font-weight: 600;
}

.sideMenuContent .form-group {
  margin: 0;
}

.form-group .error,
.error-out,
.success-out {
  display: block;
  background: white;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2;
  color: #b92022;
  color: var(--red);
  border-bottom-right-radius: 3px;
  border-bottom-left-radius: 3px;
}

SelectText {
  display: flex !important;
  align-items: center;
}

.error-out {
  padding-bottom: 15px;
}

.success-out {
  padding-bottom: 15px;
  color: #66b139;
  color: var(--green);
}

label.progressState {
  width: 100%;
  color: white;
  font-size: 14px;
  font-weight: 500;
  text-align: center;
}

.responseOutput_gif {
  width: 180px;
  display: block;
  margin: auto;
}

.responseOutput .success-out {
  padding-bottom: 15px;
  color: #66b139;
  color: var(--green);
}

.responseOutput .error-out {
  background-color: transparent;
  padding-bottom: 15px;
  color: #b92022;
  color: var(--red);
}

.message-box > .success-out {
  padding-top: 15px;
  background-color: transparent;
  font-size: 16px;
}

/* SIDE MENUS MOBILE */
.siteHeader.home .buttonSideMenu span {
  color: white;
}

.buttonSideSearch,
.buttonSideMenu {
  display: flex;
  align-items: center;
}

.buttonSideMenu {
  justify-content: flex-end;
}

.buttonSideMenu span {
  cursor: pointer;
  font-size: 20px;
  color: #302877;
  color: var(--violet);
}

.sideSearch {
  position: fixed;
  width: calc(100% - 30px);
  top: 0;
  height: 100vh;
  z-index: 9999;
  visibility: hidden;
  padding: 30px;
  padding-top: 45px;
  background-color: #f3f3f3;
  box-shadow: 5px 0px 0px rgba(150, 150, 150, 0.5);
  transform: translateX(-100%);
  opacity: 0;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  -webkit-transform: translateX(-100%);
  -moz-transform: translateX(-100%);
  -ms-transform: translateX(-100%);
  -o-transform: translateX(-100%);
}

.sideSearch.active {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transform: translateX(0%);
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
}

.sideSearch .icon-close-icon {
  position: absolute;
  float: right;
  top: 15px;
  right: 30px;
  cursor: pointer;
}

.sideSearch .searchForm {
  width: 100%;
  margin-bottom: 40px;
}

.sideSearch .searchForm .searchForm_wrapper {
  padding: 0;
  width: 100%;
}

.sideSearch .searchForm .searchForm_wrapper .searchForm_field {
  width: 100%;
}

.sideSearch .searchForm .searchForm_wrapper .searchForm_field input[type="text"] {
  background-color: #fff;
  padding: 20px 15px;
}

.sideSearch .searchForm .searchForm_wrapper .searchForm_filter {
  display: none;
}

.sideSearch .searchForm .searchForm_wrapper .searchForm_submit button {
  height: 40px;
  width: 40px;
}

.sideSearch .searchForm .searchForm_wrapper .searchForm_submit span {
  font-size: 20px;
}

.sideSearch .filterMenu .sideMenuContent {
  background-color: #fff;
  overflow-y: scroll;
  clear: both;
  height: auto;
  max-height: 50vh;
}

.sideMenu {
  position: fixed;
  right: 0;
  visibility: hidden;
  height: 100vh;
  padding: 30px;
  box-sizing: border-box;
  z-index: 999;
  background-color: #302877;
  background-color: var(--violet);
  display: block;
  width: calc(100% - 30px);
  box-shadow: -5px 0px 0px rgba(40, 40, 119, 0.5);
  transform: translateX(100%);
  opacity: 0;
  transition: all 0.4s ease;
  -webkit-transform: translateX(100%);
  -moz-transform: translateX(100%);
  -ms-transform: translateX(100%);
  -o-transform: translateX(100%);
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.sideMenu.active {
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transform: translateX(0%);
  opacity: 1;
  visibility: visible;
  -webkit-transform: translateX(0%);
  -moz-transform: translateX(0%);
  -ms-transform: translateX(0%);
  -o-transform: translateX(0%);
}

.sideMenu .icon-close-icon {
  float: right;
  color: white;
  cursor: pointer;
}

.sideMenu .sideMenu-top {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}

.sideMenu .sideMenu-top .ata_logo {
  width: 138px;
}

.sideMenu .sideMenu-top .lang-select {
  margin-left: 20px;
}

.sideMenu .sideMenu-top .lang-select .langItem img {
  width: 40px;
}

.sideMenu .sideMenu-content > a {
  padding: 0;
  font-size: 14px;
  width: 100%;
  text-align: left;
  display: block;
  font-weight: 700;
  color: white;
  text-decoration: none;
}

.sideMenu .sideMenu-content .galleriesSelect {
  border-bottom: 1px solid white;
  border-top: 1px solid white;
  padding: 20px 0 15px;
}

.sideMenu .sideMenu-content .galleriesSelect .galleriesSelect_button {
  padding: 0;
  font-size: 14px;
  width: 100%;
  text-align: left;
  display: block;
  font-weight: 700;
  color: white;
}

.sideMenu .sideMenu-content .galleriesSelect .galleriesSelect_button span {
  float: right;
  font-size: 16px;
}

.sideMenu .sideMenu-content .galleriesSelect > div {
  position: relative;
}

.sideMenu .sideMenu-content .galleriesSelect .galleriesSelect_menu {
  position: relative;
  margin: 0;
  padding: 0;
  box-shadow: none;
  padding-top: 20px;
}

.sideMenu .sideMenu-content .galleriesSelect .galleriesSelect_menu li a {
  color: white;
  margin: 0;
  padding-left: 0;
  font-size: 12px;
}

.sideMenu .sideMenu-content .galleriesSelect .galleriesSelect_menu li a:hover {
  background-color: inherit;
}

.sideMenu .sideMenu-content > a {
  padding: 20px 0;
  text-decoration: none;
}

.sideMenu .sideMenu-content .username {
  position: relative;
  font-size: 14px;
  color: white;
  padding: 20px 0;
  font-weight: 600;
}

.sideMenu .sideMenu-content .username .notificationCounter {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.sideMenu .sideMenu-content .userMenu-item {
  display: block;
  font-size: 13px;
  font-weight: 600;
  padding: 5px 0;
  color: white;
  text-decoration: none;
}

.sideMenu .sideMenu-content p {
  margin: 0;
}

/* ADMINISTRAÇÃO */
.progress {
  min-width: 280px;
}

.progress .progress-bar-striped {
  background-repeat: repeat;
}

.progress .progress-bar {
  background-color: #4175d4;
  background-color: var(--blue);
}

.menuDrop {
  flex: none;
  width: 277px;
  max-width: none;
  box-sizing: content-box;
}

.form-group {
  margin-bottom: 30px;
}

.warningCertificate {
  display: block;
  position: relative;
  color: #4175d4;
  color: var(--blue);
  font-weight: 600;
  font-size: 14px;
}

.warningCertificate span {
  font-size: 16px;
}

.adminLangSwitcher label .renew-certificate .fileField,
.adminForms .renew-certificate .fileField {
  border-bottom-color: #4175d4;
  border-bottom-color: var(--blue);
}

.adminLangSwitcher label .renew-certificate .importantSpecs,
.adminForms .renew-certificate .importantSpecs {
  border: 2px solid #4175d4;
  border: 2px solid var(--blue);
  border-top-color: #f3f3f3;
}

.adminLangSwitcher label .importantSpecs,
.adminForms .importantSpecs {
  position: absolute;
  bottom: -22px;
  left: 0;
  line-height: 1;
  padding: 0 5px 2px;
  background-color: #f3f3f3;
  border: 2px solid #828990;
  border: 2px solid var(--grey);
  border-top-color: #f3f3f3;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  -ms-border-radius: 2px;
  -o-border-radius: 2px;
}

.adminLangSwitcher label .importantSpecs span,
.adminForms .importantSpecs span {
  color: black;
  font-size: 14px;
  font-weight: 600;
  color: #4175d4;
  color: var(--blue);
  cursor: help;
}

.adminLangSwitcher label .importantSpecs span:hover ~ .importantSpecs_content,
.adminForms .importantSpecs span:hover ~ .importantSpecs_content {
  visibility: visible;
  opacity: 1;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
}

.adminLangSwitcher label .importantSpecs .importantSpecs_content,
.adminForms .importantSpecs .importantSpecs_content {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  transition: all 0.4s ease;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -ms-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  background-color: white;
  z-index: 9;
  width: 240px;
  font-size: 14px;
  color: black;
  line-height: 1.4;
  top: 0;
  margin-top: -40px;
  margin-left: 40px;
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
  box-shadow: 0 0 7px rgba(0, 0, 0, 0.4);
  padding: 10px;
}

.adminLangSwitcher label label,
.adminForms label {
  font-size: 14px;
  display: block;
  margin: 0;
  padding: 15px 0 10px;
  color: #302877;
  color: var(--violet);
  font-weight: 600;
  text-align: left;
}

.adminLangSwitcher label .wrapperImgUploads,
.adminForms .wrapperImgUploads {
  display: block;
  position: relative;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: auto;
  margin-top: 30px;
  margin-bottom: 30px;
}

.adminLangSwitcher label .wrapperImgUploads img,
.adminForms .wrapperImgUploads img {
  display: block;
  max-height: 230px;
  object-fit: cover;
  margin: auto;
  position: relative;
}

.adminLangSwitcher label .wrapperImgUploads img:after,
.adminForms .wrapperImgUploads img:after {
  content: '';
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  z-index: 99;
  background-color: red;
}

.adminLangSwitcher label .wrapperImgUploads span,
.adminForms .wrapperImgUploads span {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  font-size: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.adminLangSwitcher select,
.adminForms select {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  cursor: pointer;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid #828990;
  border-bottom: 2px solid var(--grey);
  background-color: #f3f3f3;
  padding: 0px 30px;
  height: 48px;
  width: 100%;
  font-size: 14px;
  font-weight: 700;
  color: #828990;
  color: var(--grey);
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3MC41OSAzNS4yOSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiM4Mjg5OTA7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJMYXllcl8yIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxnIGlkPSJMYXlvdXQiPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIzNS4yOSAwIDcwLjU5IDAgMzUuMjkgMzUuMjkgMCAwIDM1LjI5IDAiLz48L2c+PC9nPjwvc3ZnPg==");
  background-position: calc(100% - 30px) 50%;
  background-size: 14px;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.adminLangSwitcher select:disabled,
.adminForms select:disabled {
  cursor: no-drop;
  background-color: #f3f3f3;
}

.adminLangSwitcher select {
  text-align: center;
}

.adminLangSwitcher select:focus, .adminLangSwitcher select:active, .adminForms select:focus, .adminForms select:active {
  outline: none;
  box-shadow: none;
  background-color: #f3f3f3;
  color: #828990;
  color: var(--grey);
  border-color: #828990;
  border-color: var(--grey);
}

.AdminMenu {
  display: block;
  overflow: hidden;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.AdminMenu .menuTitle {
  font-size: 16px;
  background-color: #302877;
  background-color: var(--violet);
  padding: 15px;
  color: white;
  font-weight: 500;
  margin: 0;
}

.AdminMenu ul {
  list-style: none;
  padding: 0;
  margin: 0;
  background-color: #f3f3f3;
  padding-bottom: 40px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.AdminMenu .menuItem {
  position: relative;
  padding: 15px 0;
  margin: 0 15px;
  font-size: 14px;
  font-weight: 500;
  color: #828990;
  color: var(--grey);
  border-bottom: 1px solid #828990;
  border-bottom: 1px solid var(--grey);
}

.AdminMenu .menuItem a {
  display: block;
  color: #828990;
  color: var(--grey);
  text-decoration: none;
}

.AdminMenu .menuItem .active {
  color: #302877;
  color: var(--violet);
  font-weight: 700;
}

.AdminMenu .menuItem .notificationCounter {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
}

.AdminMenu.menuMobile {
  width: 100%;
}

.AdminMenu.menuMobile .AdminMenubtn {
  position: relative;
  cursor: pointer;
  width: 100%;
  border: 2px solid black;
  border-radius: 10px;
  font-size: 14px;
  padding: 15px 30px;
  text-align: center;
  font-size: 14px;
  font-weight: 700;
  color: #302877;
  color: var(--violet);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

.AdminMenu.menuMobile .AdminMenubtn::after {
  content: '\e907';
  font-family: 'icomoon';
  position: absolute;
  right: 30px;
}

.AdminMenu.menuMobile .submenuAdmin {
  display: none;
  transition: all 0.3s ease;
  opacity: 0;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
}

.AdminMenu.menuMobile .submenuAdmin.toggled {
  display: block;
  opacity: 1;
  transition: opacity 0.3s ease;
  -webkit-transition: opacity 0.3s ease;
  -moz-transition: opacity 0.3s ease;
  -ms-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
}

.adminForms {
  display: block;
  width: 100%;
  padding-bottom: 60px;
}

.adminSubForms {
  display: block;
  width: 100%;
  padding-bottom: 60px;
  padding: 0;
  margin-bottom: 15px;
  display: flex;
}

.adminSubForms .col:first-child {
  padding-left: 0;
}

.adminForms .socialNet-imagePreview {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto 20px;
}

.adminForms .form-check {
  display: flex;
  align-items: center;
}

.adminForms input[type="text"], .adminForms input[type="email"], .adminForms input[type="password"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 23px 30px;
  margin: 0;
  line-height: 1;
  background-color: #f3f3f3;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid #828990;
  border-bottom: 2px solid var(--grey);
  color: #828990;
  color: var(--grey);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  font-size: 14px;
  font-weight: 700;
}

.adminForms input[type="text"]::-webkit-input-placeholder, .adminForms input[type="text"]::-webkit-input-placeholder, .adminForms input[type="email"]::-webkit-input-placeholder, .adminForms input[type="email"]::-webkit-input-placeholder, .adminForms input[type="password"]::-webkit-input-placeholder, .adminForms input[type="password"]::-webkit-input-placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms input[type="text"]::-webkit-input-placeholder, .adminForms input[type="email"]::-webkit-input-placeholder, .adminForms input[type="password"]::-webkit-input-placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms input[type="text"]::-webkit-input-placeholder, .adminForms input[type="text"]::placeholder, .adminForms input[type="email"]::-webkit-input-placeholder, .adminForms input[type="email"]::placeholder, .adminForms input[type="password"]::-webkit-input-placeholder, .adminForms input[type="password"]::placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms .countrySelector div[class*="control"] {
  background-color: #f3f3f3;
  border: 0;
  border-bottom: 2px solid #828990;
  border-bottom: 2px solid var(--grey);
  border-radius: 0;
  font-size: 14px;
  padding: 0 20px;
  box-shadow: none;
  cursor: pointer;
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
}

.adminForms .countrySelector div[class*="control"] > div:not([class*="IndicatorsContainer"]) {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.adminForms .countrySelector div[class*="control"] div[class*="IndicatorsContainer"] span[class*="indicatorSeparator"] {
  display: none;
}

.adminForms .countrySelector div[class*="control"] div[class*="IndicatorsContainer"] div[class*="indicatorContainer"] {
  position: relative;
}

.adminForms .countrySelector div[class*="control"] div[class*="IndicatorsContainer"] div[class*="indicatorContainer"] svg {
  display: none;
}

.adminForms .countrySelector div[class*="control"] div[class*="IndicatorsContainer"] div[class*="indicatorContainer"]:after {
  content: "";
  width: 16px;
  height: 16px;
  display: block;
  position: absolute;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3MC41OSAzNS4yOSI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiM4Mjg5OTA7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJMYXllcl8yIiBkYXRhLW5hbWU9IkxheWVyIDIiPjxnIGlkPSJMYXlvdXQiPjxwb2x5Z29uIGNsYXNzPSJjbHMtMSIgcG9pbnRzPSIzNS4yOSAwIDcwLjU5IDAgMzUuMjkgMzUuMjkgMCAwIDM1LjI5IDAiLz48L2c+PC9nPjwvc3ZnPg==");
  background-size: 16px;
  background-repeat: no-repeat;
  top: 0;
}

.adminForms .countrySelector div[class*="menu"] {
  z-index: 9;
}

.adminForms .countrySelector div[class*="ValueContainer"] {
  height: 46px;
}

.adminForms .countrySelector div[class*="ValueContainer"] div[class*="placeholder"] {
  color: #828990;
  color: var(--grey);
  font-style: italic;
  font-weight: 500;
}

.adminForms .countrySelector div[class*="ValueContainer"] div[class*="singleValue"] {
  color: #828990;
  color: var(--grey);
  font-weight: 700;
}

.adminForms .countrySelector div[class*="Input"] {
  color: #828990;
  color: var(--grey);
}

.adminSubForms input[type="text"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  padding: 23px 30px;
  margin: 0;
  line-height: 1;
  background-color: #f3f3f3;
  border: 0;
  border-radius: 0;
  border-bottom: 2px solid #828990;
  border-bottom: 2px solid var(--grey);
  color: #828990;
  color: var(--grey);
  -webkit-border-radius: 0;
  -moz-border-radius: 0;
  -ms-border-radius: 0;
  -o-border-radius: 0;
  font-size: 14px;
  font-weight: 700;
}

.adminForms input[type="text"]::-webkit-input-placeholder, .adminForms input[type="text"]::-webkit-input-placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms input[type="text"]::-webkit-input-placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms input[type="text"]::-webkit-input-placeholder, .adminForms input[type="text"]::placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms input[type="email"]::-webkit-input-placeholder, .adminForms input[type="email"]::-webkit-input-placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms input[type="email"]::-webkit-input-placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms input[type="email"]::-webkit-input-placeholder, .adminForms input[type="email"]::placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms input[type="password"]::-webkit-input-placeholder, .adminForms input[type="password"]::-webkit-input-placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms input[type="password"]::-webkit-input-placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms input[type="password"]::-webkit-input-placeholder, .adminForms input[type="password"]::placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminSubForms input[type="text"]::-webkit-input-placeholder, .adminSubForms input[type="text"]::-webkit-input-placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminSubForms input[type="text"]::-webkit-input-placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminSubForms input[type="text"]::-webkit-input-placeholder, .adminSubForms input[type="text"]::placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms input[type="text"]:active, .adminForms input[type="text"]:focus {
  box-shadow: none;
  outline: none;
}

.adminForms input[type="email"]:active, .adminForms input[type="email"]:focus {
  box-shadow: none;
  outline: none;
}

.adminForms input[type="password"]:active, .adminForms input[type="password"]:focus {
  box-shadow: none;
  outline: none;
}

.adminSubForms input[type="text"]:active, .adminSubForms input[type="text"]:focus {
  box-shadow: none;
  outline: none;
}

.adminForms input[type="checkbox"],
.customCheckbox input[type="checkbox"] {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  position: relative;
  cursor: pointer;
  margin: 0;
  margin-right: 10px;
  display: table;
  margin-top: 0;
  align-items: center;
  justify-content: center;
  width: 18px;
  min-width: 18px;
  height: 18px;
  border: 2px solid #828990;
  border: 2px solid var(--grey);
  border-radius: 3px;
  background-color: transparent;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}

.adminForms input[type="checkbox"]:checked,
.customCheckbox input[type="checkbox"]:checked {
  position: relative;
  border-color: #4175d4;
  border-color: var(--blue);
  background-color: #4175d4;
  background-color: var(--blue);
  display: table;
  align-items: center;
  justify-content: center;
}

.adminForms input[type="checkbox"]:checked:after,
.customCheckbox input[type="checkbox"]:checked:after {
  content: "\e913";
  color: white;
  position: absolute;
  font-family: "icomoon";
  font-size: 12px;
  top: 0px;
  left: 50%;
  transform: translate(-50%);
  -webkit-transform: translate(-50%);
  -moz-transform: translate(-50%);
  -ms-transform: translate(-50%);
  -o-transform: translate(-50%);
}

.adminForms input[type="checkbox"]:checked, .adminForms input[type="checkbox"]:active, .adminForms input[type="checkbox"]:focus {
  outline: none;
}

.customCheckbox input[type="checkbox"]:checked, .customCheckbox input[type="checkbox"]:active, .customCheckbox input[type="checkbox"]:focus {
  outline: none;
}

.adminForms input[type="checkbox"] ~ label,
.customCheckbox input[type="checkbox"] ~ label {
  color: #828990;
  color: var(--grey);
  font-weight: 700;
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.adminSubForms .adminSubForms_actions {
  flex: none;
  max-width: none;
  width: 40px;
  align-items: flex-end;
  display: flex;
}

.adminSubForms .form-group {
  margin: 0;
}

.adminSubForms_actions button {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border: 0;
  background-color: transparent;
}

.adminForms .fileField {
  background-color: #f3f3f3;
  position: relative;
  border-bottom: 2px solid #828990;
  border-bottom: 2px solid var(--grey);
  padding: 10px 30px;
  display: flex;
  align-items: center;
}

.adminForms .fileField input[type="file"] {
  opacity: 0;
  z-index: 9;
  cursor: pointer;
}

.adminForms .fileField input[type="file"]:disabled {
  cursor: no-drop;
}

.adminForms .fileField input[type="file"]:disabled ~ .iconFile_button {
  z-index: 9;
  cursor: no-drop;
}

.adminForms .fileField .error {
  position: absolute;
  left: 0;
  bottom: -19px;
}

.adminForms .fileField .iconFile_Placeholder {
  position: absolute;
  font-size: 14px;
  font-weight: 500;
  font-style: italic;
  color: #828990;
  color: var(--grey);
  z-index: 2;
}

.adminForms .fileField .iconFile_button {
  position: absolute;
  right: 0px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  height: 30px;
  width: 100%;
}

.adminForms .fileField .iconFile_button .icon-plus {
  position: absolute;
  right: 30px;
  color: #4175d4;
  color: var(--blue);
  font-size: 14px;
  -webkit-text-stroke-width: 1px;
}

.adminForms .fileField .iconFile_button .iconFile_Name {
  font-size: 14px;
  font-weight: 600;
  margin: 0;
  margin-right: 40px;
  color: #828990;
  color: var(--grey);
  display: flex;
}

.adminForms .fileField .iconFile_button .iconFile_Name a {
  position: relative;
  display: block;
  color: #828990;
  color: var(--grey);
}

.adminForms .fileField .iconFile_button .iconFile_Name > span {
  color: #4175d4;
  color: var(--blue);
  font-size: 16px;
  margin-right: 5px;
}

.adminForms .formSubmit {
  justify-content: flex-end;
}

.adminForms .formSubmit button {
  margin-left: 30px;
  background-color: #302877;
  background-color: var(--violet);
}

.adminForms .formSubmit button:hover, .adminForms .formSubmit button:active {
  background-color: #272064;
  background-color: var(--violet-dark);
}

.adminForms .formSubmit button:focus {
  background-color: #302877;
  background-color: var(--violet);
}

.adminForms .table-imagePreview {
  display: flex;
  align-items: center;
  margin: auto;
  margin-bottom: 20px;
}

.adminForms .table-imagePreview a {
  color: white;
  font-size: 20px;
}

.adminForms .table-filePreview {
  background-color: #f3f3f3;
  padding: 13px 30px;
  font-weight: 600;
  margin-bottom: 15px;
  border-bottom: 2px solid #828990;
  border-bottom: 2px solid var(--grey);
  font-size: 14px;
}

.adminForms .table-filePreview__renewfile {
  border-bottom-color: #4175d4;
  border-bottom-color: var(--blue);
}

.adminForms .table-filePreview a {
  color: #4175d4;
  color: var(--blue);
}

.adminForms .table-filePreview a:hover {
  text-decoration: none;
  color: #302877;
  color: var(--violet);
}

.adminForms textarea {
  border: 0;
  border-bottom: 2px solid #828990;
  border-bottom: 2px solid var(--grey);
  background-color: #f3f3f3;
  color: #828990;
  color: var(--grey);
  font-size: 14px;
  padding: 23px 30px;
  font-weight: 600;
  border-radius: 0px;
  -webkit-border-radius: 0px;
  -moz-border-radius: 0px;
  -ms-border-radius: 0px;
  -o-border-radius: 0px;
}

.adminForms textarea::-webkit-input-placeholder, .adminForms textarea::-webkit-input-placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms textarea::-webkit-input-placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms textarea::-webkit-input-placeholder, .adminForms textarea::placeholder {
  font-style: italic;
  font-weight: 500;
}

.adminForms textarea:focus, .adminForms textarea:active {
  outline: none;
  box-shadow: none;
  background-color: #f3f3f3;
  color: #828990;
  color: var(--grey);
  border-color: #828990;
  border-color: var(--grey);
}

.adminForms .showOnlySelected {
  cursor: not-allowed !important;
}

.contentTitle {
  display: flex;
  align-items: center;
  color: #302877;
  color: var(--violet);
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  padding: 15px 0;
  border-bottom: 1px solid #dedede;
  margin: 0;
  margin-bottom: 15px;
}

.pageClientHead {
  padding: 10px 0;
  border-bottom: 1px solid #dedede;
  margin: 0;
  margin-bottom: 15px;
}

.pageClientHead > .contentTitle {
  padding: 0;
  border-bottom: 0;
  margin: 0;
  margin-bottom: 0;
}

.pageClientHead .separador::before {
  content: "";
  width: 25px;
  display: block;
  height: 1px;
  background-color: black;
  font-size: 0;
  margin: 13px 15px 0;
}

.pageClientHead .nav-link {
  padding: 5px 15px;
  font-size: 14px;
  font-weight: 600;
  color: black;
  line-height: 1;
  border-radius: 000;
  -webkit-border-radius: 000;
  -moz-border-radius: 000;
  -ms-border-radius: 000;
  -o-border-radius: 000;
  background-color: #f3f3f3;
  border-bottom: 3px solid #f3f3f3;
  margin: 0;
}

.pageClientHead .nav-link.active {
  background-color: #f3f3f3;
  color: black;
  margin: 0;
  border-bottom: 3px solid #302877;
  border-bottom: 3px solid var(--violet);
}

.permissionsTitle {
  padding: 15px 0;
  border-bottom: 1px solid #dedede;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.permissionsTitle > .title {
  color: #302877;
  color: var(--violet);
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}

.permissionsTitle > .btAdd {
  background: #302877;
  background: var(--violet);
}

.data-table-extensions {
  padding: 15px 0;
}

.data-table-extensions .data-table-extensions-filter .icon {
  /*background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA3MiA3MiI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiMzMDI4Nzc7fTwvc3R5bGU+PC9kZWZzPjxnIGlkPSJMYXlvdXQiPjxwYXRoIHN0cm9rZS13aWR0aD0iMiIgY2xhc3M9ImNscy0xIiBkPSJNMjUuODYsNDcuMzJBMjEuNDQsMjEuNDQsMCwxLDEsNDcuMywyNS44OCwyMS40NywyMS40NywwLDAsMSwyNS44Niw0Ny4zMlptMC00MC41NUExOS4xMSwxOS4xMSwwLDEsMCw0NSwyNS44OCwxOS4xMywxOS4xMywwLDAsMCwyNS44Niw2Ljc3WiIvPjxwYXRoIHN0cm9rZS13aWR0aD0iMiIgY2xhc3M9ImNscy0xIiBkPSJNNjYuNDEsNjcuNTZhMS4xMywxLjEzLDAsMCwxLS44Mi0uMzRsLTI2LTI2YTEuMTcsMS4xNywwLDAsMSwxLjY1LTEuNjVsMjYsMjZhMS4xOCwxLjE4LDAsMCwxLDAsMS42NUExLjE2LDEuMTYsMCwwLDEsNjYuNDEsNjcuNTZaIi8+PC9nPjwvc3ZnPg==");    */
}

.data-table-extensions .data-table-extensions-filter .filter-text {
  color: #828990;
  color: var(--grey);
  border-bottom: 2px solid #828990;
  border-bottom: 2px solid var(--grey);
  font-weight: 600;
}

.data-table-extensions .data-table-extensions-filter .filter-text:hover, .data-table-extensions .data-table-extensions-filter .filter-text:focus, .data-table-extensions .data-table-extensions-filter .filter-text:active {
  border-bottom-color: #828990;
  border-bottom-color: var(--grey);
}

.data-table-extensions .data-table-extensions-filter .filter-text::-webkit-input-placeholder, .data-table-extensions .data-table-extensions-filter .filter-text::-webkit-input-placeholder {
  color: #828990;
  color: var(--grey);
  font-weight: 400;
  font-style: italic;
}

.data-table-extensions .data-table-extensions-filter .filter-text::-webkit-input-placeholder {
  color: #828990;
  color: var(--grey);
  font-weight: 400;
  font-style: italic;
}

.data-table-extensions .data-table-extensions-filter .filter-text::-webkit-input-placeholder, .data-table-extensions .data-table-extensions-filter .filter-text::placeholder {
  color: #828990;
  color: var(--grey);
  font-weight: 400;
  font-style: italic;
}

.table-DefauWrapper, .rdt_Table {
  border-radius: 5px;
  overflow-x: scroll;
  margin-bottom: 60px;
}

.table-DefauWrapper .tableInner, .rdt_Table .tableInner {
  min-width: 760px;
}

.rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  height: 60px;
  background: #302877;
  background: var(--violet);
  margin: 0;
}

.rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable {
  color: white;
  font-size: 14px;
  font-weight: 600;
}

.rdt_TableHead .rdt_TableHeadRow .rdt_TableCol .rdt_TableCol_Sortable:hover {
  color: #f3f3f3;
}

.rdt_TableHead .rdt_TableHeadRow .rdt_TableCol:last-child {
  flex: none;
  width: 120px;
  display: flex;
  justify-content: space-between;
}

.rdt_TableBody .rdt_TableRow {
  background-color: #f3f3f3;
}

.rdt_TableBody .rdt_TableRow:hover {
  background-color: #d8d8d8;
}

.rdt_TableBody .rdt_TableRow .rdt_TableCell {
  font-size: 14px;
  position: relative;
}

.rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child {
  flex: none;
  width: 120px;
}

.rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child > div {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.rdt_TableBody .rdt_TableRow .rdt_TableCell:last-child > div .table-actionsItem {
  cursor: pointer;
}

.rdt_TableBody .rdt_TableRow .rdt_TableCell .wrapperImgUploads {
  position: relative;
}

.rdt_TableBody .rdt_TableRow .rdt_TableCell .wrapperImgUploads span {
  position: absolute;
  background-color: rgba(0, 0, 0, 0.4);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 9;
  font-size: 30px;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdt_TableBody .rdt_TableRow .rdt_TableCell img:not(.socialNet_img) {
  object-fit: cover;
  max-width: 100%;
  /*width: auto;*/
  height: 100%;
  max-height: 240;
  display: block;
  margin: auto;
}

.rdt_Pagination {
  padding: 0;
  border-top: 1px solid #dedede;
}

.rdt_Pagination > span, .rdt_Pagination select {
  font-size: 14px;
  font-weight: 500;
  color: black;
}

.rdt_Pagination button > svg {
  fill: #302877;
  fill: var(--violet);
}

.table-item.header {
  display: flex;
  height: 60px;
  background: #302877;
  background: var(--violet);
  margin: 0;
}

.table-item.header .col {
  font-size: 16px;
  font-weight: 700;
  color: white;
}

.table-item:not(.header) {
  /*height: 60px;*/
  display: flex;
  padding: 4px 0px;
  margin: 0;
  background-color: #f3f3f3;
}

.table-item.button {
  display: flex;
  background: #302877;
  background: var(--violet);
  margin: 5px 0px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.table-item.button > .table-text {
  color: #fff;
}

.table-item .table-col {
  display: flex;
  align-items: center;
}

.table-item .table-col.ops {
  justify-content: space-around !important;
}

.table-item .table-col.ops i {
  cursor: pointer;
}

.table-item .table-col .editPermissions {
  margin: 0;
}

.table-item .table-col .editPermissions .editPermissionsCheck {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.table-item .table-col .editPermissions label {
  display: none;
}

.table-item .table-col .editPermissions .form-check-input {
  margin: 0;
}

.table-item .table-image {
  flex: none;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.table-item .table-add-options {
  display: flex;
  justify-content: flex-end;
}

.table-item .table-add-options > .buttons {
  background: #302877;
  background: var(--violet);
  margin: 2px 5px;
  padding: 2px 8px;
}

.table-imagePreview {
  flex: none;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.table-item:not(.header) .table-image,
.table-imagePreview {
  background-color: #302877;
  background-color: var(--violet);
}

.homepageChange {
  position: relative;
}

.homepageChange .homefileField {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #fff;
  padding: 3px 5px;
  cursor: pointer;
}

.table-item .table-text {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.table-item .table-text:not(:first-of-type) {
  justify-content: center;
}

.table-item .table-col.thead:first-child {
  flex: none;
  width: 150px;
  max-width: none;
}

.table-item .table-text.permission-check {
  font-size: 15px;
  font-weight: bold;
  color: #302877;
  color: var(--violet);
}

.table-item .table-text.permission-check span {
  font-size: 15px;
  font-weight: bold;
  color: #302877;
  color: var(--violet);
}

.table-item .table-text.permission-check:not(.nAllowed) {
  cursor: pointer;
}

.table-item .table-link {
  font-size: 14px;
  color: #000;
  font-weight: 400;
}

.table-item .table-actions {
  display: flex;
  width: 100px;
  flex: none;
  justify-content: space-around;
}

.table-item .table-actions > div {
  display: flex;
  width: 100px;
  flex: none;
  justify-content: space-around;
}

.table-item .table-actions .table-actionsItem {
  cursor: pointer;
}

.save {
  justify-content: center;
  align-items: center;
}

.save > .saveBt {
  background-color: #302877;
  background-color: var(--violet);
}

.add_Subcategory {
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  background-color: transparent;
  border: 0;
  color: #4175d4;
  color: var(--blue);
  font-size: 14px;
  font-weight: 600;
}

.add_Subcategory span {
  margin-right: 15px;
}

/* SUB-FOOTER / FOOTER */
.subFooter {
  background-color: #302877;
  background-color: var(--violet);
  padding: 60px 0;
}

.bottomFooter {
  background-color: #4175d4;
  background-color: var(--blue);
  padding: 15px 0;
}

.aboutTitle {
  color: white;
  font-size: 32px;
  font-weight: 700;
}

.aboutText {
  padding-bottom: 40px;
}

.aboutLinks {
  padding: 40px 0;
}

.aboutItem .aboutItem_title {
  color: white;
  font-size: 24px;
  font-weight: 500;
}

.aboutItem .aboutItem_text {
  white-space: pre-wrap;
  font-size: 16px;
  font-weight: 300;
  color: white;
}

.privacyLinks {
  padding: 15px 0;
  border-bottom: 1px solid white;
  margin: 0 !important;
}

.privacyLinks .col {
  padding: 0;
}

.privacyLinks a {
  color: white;
  text-decoration: none;
  font-size: 14px;
  font-weight: 500;
}

.privacyLinks a:hover {
  text-decoration: none;
  color: #e6e6e6;
}

.privacyLinks span {
  color: white;
  margin: 0 25px;
}

.ata_bottom {
  padding-top: 15px;
  padding-bottom: 30px;
}

.ata_bottom .ata_copyright {
  display: flex;
  align-items: center;
  color: white;
  font-size: 14px;
  font-weight: 500;
}

.ata_bottom .ata_copyright p {
  margin: 0;
}

.ata_bottom .ata_partnership {
  display: block;
  text-align: right;
}

.ata_bottom .ata_partnership img {
  /*margin-left: 20px;*/
  max-width: 380px;
  width: 100%;
}

.contactLink {
  display: flex;
  align-items: center;
  color: white;
  font-size: 24px;
  font-weight: 500;
}

.contactLink p {
  margin: 0;
}

.contactLink p.contactLink_link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 5px;
}

.contactLink a:hover {
  color: #4175d4;
  color: var(--blue);
  text-decoration: none;
}

.contactLink .icon-arrow-right {
  margin-left: 5px;
  font-size: 55px;
  color: #4175d4;
  color: var(--blue);
}

.content-sub {
  flex-wrap: nowrap;
}

.socialsLink {
  display: flex;
  align-items: center;
}

.socialsLink p {
  font-size: 24px;
  font-weight: 500;
  color: white;
  margin-bottom: 0;
  margin-right: 25px;
}

.socialsLink a {
  color: white;
  text-decoration: none;
}

.socialsLink a img {
  width: 50px;
  margin-bottom: 10px;
  margin-right: 10px;
}

.socialsLink a:hover {
  color: white;
  text-decoration: none;
}

/*-----------------MyList / Previous Downloads----------------------*/
.mylistRow {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 1px solid #dedede;
  position: relative;
}

.mylistRow .mylistImage {
  padding-left: 0;
}

.mylistRow .mylistTitle {
  position: relative;
  padding: 0px 0 0 20px;
}

.mylistRow .mylistTitle .mylistTitle_title {
  font-size: 16px;
  font-weight: 600;
  color: black;
  line-height: 1.2;
  padding-top: 10px;
}

.mylistRow .mylistTitle .mylistDeadlines .downDate, .mylistRow .mylistTitle .mylistDeadlines .expDate {
  font-size: 11px;
  color: #828990;
  color: var(--grey);
  font-weight: 600;
}

.mylistRow .mylistTerms {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
}

.mylistRow .mylistTerms .mylistTermsWrapper {
  display: flex;
  align-items: flex-start;
}

.mylistRow .mylistTerms .mylistTermsWrapper .customRadioBtn {
  position: relative;
  width: 18px;
  min-width: 18px;
  height: 18px;
  display: table;
  cursor: pointer;
  margin-right: 15px;
  border: 2px solid #828990;
  border: 2px solid var(--grey);
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
}

.mylistRow .mylistTerms .mylistTermsWrapper .customRadioBtn.active::before {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-color: #828990;
  background-color: var(--grey);
  border: 2px solid white;
  border-radius: 20px;
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  -ms-border-radius: 20px;
  -o-border-radius: 20px;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
}

.mylistRow .mylistTerms .mylistTermsWrapper p {
  font-size: 12px;
  color: #828990;
  color: var(--grey);
  font-weight: 700;
  line-height: 1.3;
  margin: 0;
}

.mylistRow .mylistTerms .mylistTermsWrapper p .licenseBtnmodal {
  color: #828990;
  color: var(--grey);
  text-decoration: underline;
  cursor: pointer;
}

.mylistRow .mylistTerms .mylistTermsWrapper_error {
  position: absolute;
  top: calc(50% + 10px);
  left: 16px;
  padding-top: 10px;
  display: flex;
  align-items: flex-start;
  color: #b92022;
  font-size: 11px;
  font-weight: 600;
  line-height: 1.2;
}

.mylistRow .mylistTerms .mylistTermsWrapper_error span {
  font-size: 16px;
  margin-right: 15px;
  margin-top: 4px;
  -webkit-text-stroke-width: 1px;
}

.mylistRow .mylistTerms .mylistTermsWrapper_error.licenseExp {
  position: relative;
  top: 0;
  left: 0;
}

.mylistRow .mylistAcceptance {
  padding-right: 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.mylistRow .mylistAcceptance .customCheckbox {
  padding-left: 0%;
}

.mylistRow .mylistAcceptance .customCheckbox input {
  margin-left: auto;
  margin-right: 0;
}

.mylistRow .mylistAcceptance > .downButton {
  margin-top: 0;
  margin-right: 0;
}

.mylistRow .mylistAcceptance > .downButton .customSubmitBtn {
  min-width: auto;
  width: 100%;
  margin-right: 0;
}

.downButton, .mylist .downButton {
  margin-top: 35px;
  margin-left: -15px;
  margin-right: -15px;
}

.downButton_more, .mylist .downButton_more {
  margin-top: 35px;
  margin-left: -15px;
  margin-right: -15px;
}

.downButton_more .customSubmitBtn, .mylist .downButton_more .customSubmitBtn {
  margin: auto;
  width: 225px;
}

.downButton > .customSubmitBtn, .mylist .downButton > .customSubmitBtn {
  /*margin: auto;*/
  margin-left: auto;
  width: 0;
  margin-right: -15px;
}
  
.licenseForm {
  color: #fff;
}

.licenseForm, .licenseTerms {
  font-size: 16px;
  font-weight: 600;
}

/*
    .licenseTerms > p {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; 
        white-space: pre-wrap;
        line-height: 1.5;
    }
*/

.licenseTerms > p span {
  margin-right: 15px;
  color: #4175d4;
  color: var(--blue);
}

.licenseImg .itemWrapper {
  height: 205px;
}

/*---------------Notificacoes--------------------*/
.notificationsItem {
  position: relative;
  padding: 25px 0 40px;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #dedede;
  min-height: 126px;
}

.notificationsItem .icon-circle {
  position: absolute;
  left: 0;
  color: #b92022;
  color: var(--red);
  font-size: 13px;
}

.notificationsItem .notificationsMsg {
  font-size: 16px;
  font-weight: 700;
  line-height: 1.3;
  padding-left: 40px;
}

.notificationsItem .notificationsDate {
  text-align: center;
}

.notificationsItem .notificationsDate > * {
  display: block;
}

.notificationsItem .notificationsDate label {
  font-size: 9px;
  color: #828990;
  color: var(--grey);
  margin: 0;
  font-weight: 600;
  margin-bottom: 3px;
}

.notificationsItem .notificationsDate span {
  font-size: 12px;
  font-weight: 600;
  color: #828990;
  color: var(--grey);
  line-height: 1.1;
  margin-bottom: 3px;
}

.notificationsItem .notificationsDate span.daysLeft {
  color: #302877;
  color: var(--violet);
}

.notificationsItem .notificationsBtn {
  padding-right: 0;
}

.notificationsItem.mylistRow {
  padding: 0 0 15px;
}

.notificationsItem.mylistRow:first-child {
  margin-top: 15px;
}

/*---------------Pagination--------------------*/
.pagination {
  display: flex;
  justify-content: center;
  padding: 0px 0 40px;
}

.pagination ul {
  list-style: none;
  padding: 0px;
  display: flex;
}

.pagination ul li {
  background: #f3f3f3;
  padding: 5px 15px;
  margin-right: 5px;
  border-radius: 5px;
  color: #828990;
  color: var(--grey);
  font-size: 14px;
  font-weight: 700;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
}

.pagination ul li:last-child {
  margin-right: 0px;
}

.pagination ul li:not(.active):not(.disabled) {
  cursor: pointer;
}

.pagination ul li:not(.active):not(.disabled):hover, .pagination ul li:not(.active):not(.disabled):active {
  background: #a197ff;
  background: var(--violet-light);
  color: white;
}

.pagination ul li.active {
  background: #302877;
  background: var(--violet);
  color: white;
}

.pagination ul li.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.jss1:first-child {
  padding: 0 !important;
  top: 15px !important;
  right: 15px !important;
}

/*---------------Pagination--------------------*/
/*----------- RESPONSIVE -------------*/
@media (max-width: 767px) {
  .registForm {
    margin: 0;
  }
  .itemWrapper_hover {
    visibility: visible !important;
    opacity: 1 !important;
  }
  .content.contentPage,
  footer > .container-fluid > .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .homeBG {
    display: block;
    min-height: auto;
  }
  .menuDrop {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 30px;
  }
  .loginContainer {
    padding: 30px;
  }
  .loginContainer .home-loginForm {
    padding: 30px;
  }
  .loginContainer .loginForm .loginTitle {
    margin-bottom: 30px;
  }
  .loginContainer .formLinks.form-group:last-child {
    margin-bottom: 0;
  }
  .registHeader .ata_logo,
  .headerFiltersearch .ata_logo {
    margin-left: 0;
  }
  .registHeader .ata_logo a,
  .headerFiltersearch .ata_logo a {
    padding: 0;
  }
  .registAta {
    margin-top: 0;
  }
  .registAta .registTitle {
    margin: 40px 0;
  }
  .registAta .formSubmit {
    flex-wrap: wrap;
  }
  .registAta .formSubmit .termsCondition {
    width: 100%;
    padding-bottom: 20px;
  }
  .registAta .formSubmit button[type="submit"] {
    width: 100%;
  }
  .contentFiltersearch {
    margin-top: 0;
  }
  .contentFiltersearch .titleFiltersearch {
    margin: 40px 0;
    font-size: 24px;
  }
  .contentFiltersearch_wrapper {
    width: 100%;
  }
  .contentFiltersearch_wrapper .filterFields {
    margin-bottom: 0px;
  }
  .contentFiltersearch_wrapper .filterFields select {
    max-width: none;
    width: 100%;
    margin-bottom: 30px;
  }
  .contentFiltersearch_wrapper .filterSubmit .filterSubmit_wrapper {
    padding: 0 15px;
    display: block;
    margin: auto;
    width: 180px;
  }
  .contentFiltersearch_wrapper .filterSubmit .filterSubmit_wrapper .searchSubmit {
    max-width: 100%;
    margin-bottom: 10px;
    min-width: auto;
  }
  .langItem img {
    width: 40px;
  }
  .siteHeader {
    position: relative;
  }
  .siteHeader.defaultHeader:not(.home) .container {
    padding: 0 30px;
  }
  .ata_logo {
    margin: auto;
    width: 138px;
  }
  .bannerHomepage_wrapper {
    padding: 0 30px;
  }
  .bannerHomepage_wrapper .photoFeatured {
    left: 30px;
  }
  .bannerHomepage_inner {
    min-height: 100vh;
  }
  .headSearchresults .pageTitle {
    margin-bottom: 20px;
  }
  .ata_stats {
    height: auto;
  }
  .pagesubTitle {
    font-size: 19px;
  }
  .aboutLinks {
    padding-top: 0;
  }
  .contactLink {
    display: block;
  }
  .imagePreview {
    margin-top: 10px;
    padding-bottom: 40px;
  }
  .imagePreview .ata_logo {
    width: 180px;
  }
  .socialsLink {
    display: block;
    margin-top: 40px;
  }
  .socialsLink .socialIconsWrapper {
    margin-top: 20px;
    display: block;
  }
  .leftcontent a {
    display: block;
    text-align: center;
    margin-bottom: 20px;
  }
  .leftcontent span {
    display: none;
  }
  .ata_bottom .ata_copyright {
    margin: 20px 0;
  }
  .ata_bottom .ata_copyright,
  .ata_bottom .ata_partnership {
    display: block;
    text-align: center;
  }
  .FeaturedimgsSelect_button {
    width: 100%;
  }
  .FeaturedimgsSelect.customSelect .customSelect-menu {
    width: 100%;
    max-width: none;
    right: 0;
    z-index: 9999;
  }
  .content-sub:not(.contentOffice) {
    flex-direction: column-reverse;
  }
  .contentOffice {
    flex-wrap: wrap;
  }
  .contentOffice .contentDRUP {
    padding-top: 30px;
  }
  .contentOffice .contentTitle:not(.contentTitle_sub) {
    display: none;
  }
  .pageClientHead .separador {
    display: none;
  }
  .pageClientHead .nav-item {
    width: 50%;
  }
  .pageClientHead .nav-item a {
    text-align: center;
  }
  .adminForms .formSubmit {
    flex-wrap: wrap;
    padding-top: 30px;
  }
  .adminForms .formSubmit button {
    margin: 0;
    width: 100%;
    margin-bottom: 15px;
  }
  .mylistRow {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #dedede;
  }
  .mylistRow .mylistTitle {
    position: relative;
    padding: 30px 0 0 0px;
    font-size: 16px;
    font-weight: 600;
    color: black;
    line-height: 1.2;
  }
  .mylistRow .mylistTitle .downDate {
    position: absolute;
    top: 0;
    padding-top: 10px;
    left: 0px;
    font-size: 11px;
    color: #828990;
    color: var(--grey);
    font-weight: 600;
    width: 100%;
  }
  .mylistRow .mylistTerms {
    padding-left: 0;
  }
  .mylistRow .mylistTerms .mylistTermsWrapper_error {
    position: relative;
    top: 0;
    left: 0;
  }
  .mylistRow .mylistAcceptance {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }
  .mylistRow .downButton {
    margin-top: 0;
    margin-right: 0;
  }
  .mylistRow .downButton .customSubmitBtn {
    min-width: auto;
    width: 100%;
  }
  .notificationsBtn {
    padding-top: 20px;
    padding-left: 0;
  }
  .notificationsItem .notificationsDate {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .registForm {
    margin: 0;
  }
  .bannerHomepage_inner {
    min-height: 100vh;
  }
  .content.contentPage,
  footer > .container-fluid > .container {
    padding-left: 30px;
    padding-right: 30px;
  }
  .menuDrop {
    width: 100%;
    box-sizing: border-box;
    padding-bottom: 30px;
  }
  .loginContainer {
    padding: 30px;
  }
  .loginContainer .home-loginForm {
    padding: 30px;
  }
  .loginContainer .loginForm .loginTitle {
    margin-bottom: 30px;
  }
  .loginContainer .formLinks.form-group:last-child {
    margin-bottom: 0;
  }
  .registHeader .ata_logo,
  .headerFiltersearch .ata_logo {
    margin-left: 0;
  }
  .registHeader .ata_logo a,
  .headerFiltersearch .ata_logo a {
    padding: 0;
  }
  .homeBG {
    display: block;
  }
  .ata_logo {
    margin: auto;
    width: 138px;
  }
  .langItem img {
    width: 40px;
  }
  .siteHeader {
    position: relative;
  }
  .siteHeader.defaultHeader:not(.home) .container {
    padding: 0 30px;
  }
  .contentFiltersearch {
    margin-top: 0;
  }
  .contentFiltersearch .titleFiltersearch {
    margin: 40px 0;
    font-size: 24px;
  }
  .contentFiltersearch_wrapper {
    width: 100%;
  }
  .contentFiltersearch_wrapper .filterFields {
    margin-bottom: 0px;
  }
  .contentFiltersearch_wrapper .filterFields select {
    max-width: none;
    width: 100%;
    margin-bottom: 30px;
  }
  .contentFiltersearch_wrapper .filterSubmit .searchSubmit {
    max-width: 100%;
  }
  .content-sub:not(.contentOffice) {
    flex-direction: column-reverse;
  }
  .contentOffice {
    flex-wrap: wrap;
  }
  .contentOffice .contentDRUP {
    padding-top: 30px;
  }
  .contentOffice .contentTitle:not(.contentTitle_sub) {
    display: none;
  }
  .sideMenu,
  .sideSearch {
    width: 60%;
  }
  .pageClientHead .separador {
    display: none;
  }
  .pageClientHead .nav-item {
    width: 50%;
  }
  .pageClientHead .nav-item a {
    text-align: center;
  }
  .adminForms .formSubmit {
    flex-wrap: wrap;
    padding-top: 30px;
  }
  .adminForms .formSubmit button {
    margin: 0;
    width: 100%;
    margin-bottom: 15px;
  }
  .mylistRow {
    padding-bottom: 15px;
    margin-bottom: 15px;
    border-bottom: 1px solid #dedede;
  }
  .mylistRow .mylistTitle {
    position: relative;
    padding: 30px 0 0 0px;
    font-size: 16px;
    font-weight: 600;
    color: black;
    line-height: 1.2;
  }
  .mylistRow .mylistTitle .downDate {
    position: absolute;
    top: 0;
    padding-top: 10px;
    left: 0px;
    font-size: 11px;
    color: #828990;
    color: var(--grey);
    font-weight: 600;
    width: 100%;
  }
  .mylistRow .mylistTerms {
    padding-left: 0;
  }
  .mylistRow .mylistTerms .mylistTermsWrapper_error {
    position: relative;
    top: 0;
    left: 0;
  }
  .mylistRow .mylistAcceptance {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
  }
  .mylistRow .downButton {
    margin-top: 0;
    margin-right: 0;
  }
  .mylistRow .downButton .customSubmitBtn {
    min-width: auto;
    width: 100%;
  }
  .notificationsBtn {
    padding-top: 20px;
    padding-left: 0;
  }
  .notificationsItem .notificationsDate {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .contentFiltersearch {
    margin-top: calc(50% - 440px);
  }
  .contentDRUP {
    flex: none;
    width: 65%;
    max-width: none;
    box-sizing: content-box;
  }
  .bannerHomepage_inner {
    min-height: 100vh;
  }
  .mylistRow .mylistTerms .mylistTermsWrapper_error {
    position: relative;
    top: 0;
    left: 0;
  }
}

@media (min-width: 1200px) {
  .container, .container-lg, .container-md, .container-sm, .container-xl {
    max-width: 1200px;
  }
}

